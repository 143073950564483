var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { staticClass: "tool-box__auto-music", attrs: { column: "" } },
    [
      _c(
        "v-flex",
        [
          _c("h2", { staticClass: "tool-box__auto-music__header-title" }, [
            _vm._v("Auto Music"),
          ]),
          _c(
            "v-layout",
            { attrs: { column: "" } },
            [
              _c(
                "v-flex",
                { attrs: { shrink: "" } },
                [
                  _c("v-switch", {
                    staticClass: "switch",
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _c("span", { staticClass: "v-label-white" }, [
                              _vm._v(" Disable Auto Music "),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.disableAutoMusic,
                      callback: function ($$v) {
                        _vm.disableAutoMusic = $$v
                      },
                      expression: "disableAutoMusic",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }